import { UtilService } from './util.service';
import { VariablesAttendanceService } from './variables_attendance.service';
import { PropostaCliente } from './../model-chat/proposta-cliente';
import { FirestoreService } from './firestore.service';
import { LoadingController } from '@ionic/angular';
import { SituacaoAtendimentoEnum } from '../model-chat/enumerations/situacao-atendimento.enum';
import { environment } from 'src/environments/environment';
import { Dialogo } from '../model-chat/dialogo.model';
import { Fluxo } from '../model-chat/fluxo.model';
import { Atendimento } from '../model-chat/atendimento.model';
import { Injectable, EventEmitter } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { GenericService } from 'src/shared/generic-service/generic-service.abstract';
import { Pergunta } from '../model-chat/pergunta.model';
import { Contador } from '../../shared/model-chat/contador.model';


@Injectable({ providedIn: 'root' })
export class AtendimentoService extends GenericService<Atendimento> {

  protected collection: AngularFirestoreCollection<Atendimento>;
  protected collectionFirestore: firebase.firestore.CollectionReference;
  public atualizarAtendimento = new EventEmitter();

  constructor(
    private angularFirestoreInstace: AngularFirestore,
    private loadingController: LoadingController,
    private fb: FirestoreService,
    private variablesAttendanceService: VariablesAttendanceService,
    private util: UtilService
  ) {
    super(
      (localStorage.getItem("account_path")) ? `${localStorage.getItem("account_path")}/submitted` : '',
      angularFirestoreInstace);
    this.collection = angularFirestoreInstace.collection((localStorage.getItem("account_path")) ? `${localStorage.getItem("account_path")}/submitted` : '');
    this.collectionFirestore = this.fb.getCollection((localStorage.getItem("account_path")) ? `${localStorage.getItem("account_path")}/submitted` : '');
  }


  updateAtendimento(fluxosAtivos: Fluxo[]) {

    console.log(fluxosAtivos)

    const subscription = this.get(localStorage.getItem('submitted_id')).subscribe(
      atendimento => {

        atendimento.fluxosAtivos = fluxosAtivos;
        atendimento.variaveis = this.variablesAttendanceService.getLocalVariables()
        const proposta: PropostaCliente = JSON.parse(localStorage.getItem("proposta"));
        this.util.setMomentNull(proposta);
        atendimento.proposta = Object.assign({}, proposta);
        atendimento.dataHoraFim = new Date();
        atendimento.situacao = (atendimento.situacao == SituacaoAtendimentoEnum.INICIADO) ? SituacaoAtendimentoEnum.ATENDIMENTO : atendimento.situacao;
        atendimento.situacao = (proposta.calculoPlano) ? SituacaoAtendimentoEnum.CALCULADO : atendimento.situacao;
        atendimento.situacao = (proposta.empresa) ? SituacaoAtendimentoEnum.CONCLUIDO : atendimento.situacao;
        
        this.update(atendimento).then(
          response => {
            if (!environment.production)
              console.log('ATENDIMENTO ATULIZADO', response);
          }, 
          error => {
            console.log(error)
          }
        )

        subscription.unsubscribe();

      });
  }

  async reinicializar() {

    const loading = await this.loadingController.create({ message: 'Reiniciando atendimento...' });
    await loading.present();

    this.get(localStorage.getItem('submitted_id')).subscribe(
      resAtendimento => {

        resAtendimento.situacao = SituacaoAtendimentoEnum.REINICIADO;
        resAtendimento.dataHoraFim = new Date();

        resAtendimento.docRef.update(resAtendimento).then(
          () => {

            // localStorage.removeItem('publish_id');
            localStorage.removeItem('account_id');
            localStorage.removeItem('submitted_id');
            localStorage.removeItem('list_variables_system');
            localStorage.removeItem('list_variables_attendance');
            localStorage.removeItem('account_path');
            localStorage.removeItem('planos');
            localStorage.removeItem('proposta');
            location.href = '/' + localStorage.getItem("publish_id");
          }
        )
      }
    )
  }

  getAtendimento(submitted_id: string) {
    return this.collectionFirestore.doc(submitted_id).get();
  }

  addPerguntasComplementares(atualDialogo: Dialogo, proximoDialogo: Dialogo) {
    // renicializa as perguntas do próximo dialogo para que as perguntas complementares sejam inseridas no inicio
    proximoDialogo.perguntas = [];
    atualDialogo.complementoPerguntas.forEach(pergunta => {
      proximoDialogo.perguntas.push(Object.assign({}, pergunta));
    });

    // insere os textos complementares das opções do diálogo se existirem
    atualDialogo.opcoes.forEach(opcao => {
      if (opcao.textoComplementar !== '' && opcao.selecionado && opcao.exibirComplemento) {
        const pergunta =  new Pergunta({ texto: opcao.textoComplementar}) 
        proximoDialogo.perguntas.push(Object.assign({},pergunta));
      }
    });

    // insere novamente as pergundas do próximo dialogo no final da lista
    proximoDialogo.perguntasOriginais.forEach(pergunta => {
      proximoDialogo.perguntas.push(Object.assign({}, pergunta));
    });

  }

  exibirProximoDialogo(fluxoAtivo, indiceDialogo) {
    if (fluxoAtivo.dialogos.length > indiceDialogo + 1) {
      const dialogoAtual = fluxoAtivo.dialogos[indiceDialogo];
      const proximoDialogo = fluxoAtivo.dialogos[indiceDialogo + 1];
      proximoDialogo.exibir = true;
      this.addPerguntasComplementares(dialogoAtual, proximoDialogo);
    }
  }

  seguir(
    dialogo: Dialogo,
    indiceDialogo: number,
    fluxosAtivos: Fluxo[],
    fluxoAtivo: Fluxo,
    fluxos: Fluxo[]
  ) {

    dialogo.respondido = true;
    const proximoFluxo = fluxos.find(x => x.id == dialogo.idFluxoResposta);
    if (proximoFluxo) {
      const isFluxoAtivo = fluxosAtivos.find(x => x.id == proximoFluxo.id);
      if (!isFluxoAtivo) {
        proximoFluxo.dialogos[0].exibir = true;
        fluxosAtivos.push(proximoFluxo);

        if (dialogo.complementoPerguntas.length > 0) {
          proximoFluxo.dialogos[0] =  Object.assign({}, proximoFluxo.dialogos[0]);
          this.addPerguntasComplementares(dialogo, proximoFluxo.dialogos[0]);
        }
          
      }

    } else {
      this.exibirProximoDialogo(fluxoAtivo, indiceDialogo);
    }

    fluxosAtivos.forEach(fluxo => { fluxo.dialogos.filter(x => x.alterar = false) });
    dialogo.alterar = true;

    this.updateAtendimento(fluxosAtivos);

  }



}
