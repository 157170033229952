import { AuthGuardService } from './../shared/services-admin/auth-guard.service';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [


  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full',
    
  },
  {
    path: '',
    loadChildren: () => import('./theme/site-theme/site-theme.module').then( m => m.SiteThemePageModule)
  },
  {
    path: 'lp',
    loadChildren: () => import('./site/landing-pages/landing-pages.module').then( m => m.LandingPagesPageModule),
    data: {     
      view: false
    },  
  },
  {
    path: 'view',
    loadChildren: () => import('./site/view-chat/view-chat.module').then( m => m.ViewChatPageModule),
    data: {     
      view: false
    },  
  },

  {
    path: 'auth/login',
    loadChildren: () => import('./admin/login/login.module').then( m => m.LoginPageModule),
    data: {     
      view: false
    },  
  },
 
  // {
  //   path: 'plugins',
  //   loadChildren: () => import('./plugins/plugins.module').then( m => m.PluginsPageModule)
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
