import { environment } from 'src/environments/environment';
import { UsuarioLogin } from './../model-chat/usuario-login.model';
import { AuthService } from './auth.service';
import { Injectable, EventEmitter } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, NavigationEnd } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  authEmitter = new EventEmitter();

  constructor(
    public router: Router,
    private authService: AuthService,
    private route: ActivatedRoute

  ) { }
  canActivate(): boolean {

    if (environment.production) {
      const user = this.authService.currentUser;
      const userLogado: UsuarioLogin = JSON.parse(localStorage.getItem("usuarioLogado"));
      console.log(user, userLogado)
      if (user) {
        if (userLogado.email.includes(user.email)) {
          return true;
        }
        else this.router.navigate(['/auth/login']);
      }
      else {
        this.router.navigate(['/auth/login']);
      }
    }
    else return true;

  }
}