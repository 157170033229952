
import { IVariable } from '../model-chat/variable.model';
import { Observable } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { GenericService } from 'src/shared/generic-service/generic-service.abstract';


@Injectable({ providedIn: 'root' })
export class VariablesSystemService extends GenericService<IVariable> {
 
  private path = "list_variables_system";
  public atualizarVariaveisAtendimento = new EventEmitter<boolean>();

  constructor(angularFirestoreInstace: AngularFirestore) {
    
    super(
    (localStorage.getItem("account_path"))? `${localStorage.getItem("account_path")}/variable_system`  : ''
    , angularFirestoreInstace);
  }

  setPath(accountDocRef: firebase.firestore.DocumentReference) {
    this.setPathCollection(`${accountDocRef.path}/variable_system`);
  }

    getOnVariables(): Observable<IVariable[]> {
    return new Observable(observer => {
      this.list().subscribe(
        (variables) => {
          const variaveis_sistema = [];
          variables.forEach(variable => {
            delete variable.docRef;
            variaveis_sistema.push(variable);
          });
          localStorage.setItem(this.path, JSON.stringify(variaveis_sistema));
          observer.next(variaveis_sistema);
        }
      );
    });
  }

  getLocalVariables(): IVariable[] {
    const variaveis_sistema: IVariable[] = JSON.parse(localStorage.getItem(this.path));
    return variaveis_sistema;
  }

  setLocalVariables( variaveis_sistema: IVariable[]): IVariable[] {
    localStorage.setItem(this.path, JSON.stringify(variaveis_sistema));
    return variaveis_sistema;
  }

  setLocalVariavel(key, valor) {
    const variaveis_sistema: IVariable[] = JSON.parse(localStorage.getItem(this.path));
    const index = (variaveis_sistema.findIndex(x => x.key.includes(key)));
    variaveis_sistema[index].value = valor;
    localStorage.setItem(this.path, JSON.stringify(variaveis_sistema));
    return variaveis_sistema;
  }

  getVariable(key: string): any {
    const variaveis_sistema = this.getLocalVariables();
    if (variaveis_sistema)
    {
      const variable = variaveis_sistema.find(x => x.key.includes(key))
      if(variable)
        return variable.value;
      else undefined;
    }
      
    else
      return undefined;
  }

  replaceVariableText(texto: string ) {
    const variaveis_sistema: IVariable[] = JSON.parse(localStorage.getItem(this.path));

    if(!variaveis_sistema) {
      this.getOnVariables().subscribe(
        response => {
          return this.replaceVariableText(texto);
        }
      );
    } else {
      variaveis_sistema.forEach(variable => {
        if (variable.value != null)
          texto = texto.replace(variable.key, this.getVariable(variable.key));
      });
      return texto;
    }

   
  }


}
