import { ScriptService } from 'src/shared/services-chat/script.service';
import { VariablesSystemService } from './../shared/services-chat/variables_system.service';
import { VariablesAttendanceService } from './../shared/services-chat/variables_attendance.service';
import { AuthService } from './../shared/services-admin/auth.service';
import { UsuarioLoginService } from '../shared/services-chat/UsuarioLogin.service';
import { AdminPageModule } from './admin/admin.module';
import { ContadoresService } from '../shared/services-chat/contadores.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InterceptorModule } from 'src/shared/auth/interceptor/interceptor.module';
import { FluxoService } from '../shared/services-chat/fluxo.service';
import { AtendimentoService } from '../shared/services-chat/atendimento.service';


@NgModule({
  declarations: [
    AppComponent,
  ],
  exports: [

  ],
  entryComponents: [
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios'
      // mode: 'md'
    }),
    AppRoutingModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    InterceptorModule,
    AdminPageModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    FluxoService,
    AtendimentoService,
    UsuarioLoginService,
    AuthService,
    ContadoresService, 
    VariablesAttendanceService, 
    VariablesSystemService,
    ScriptService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
 

  constructor(
   
  ) {
    

  }
}
