import { IVariable } from '../model-chat/variable.model';
import { Observable } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { GenericService } from 'src/shared/generic-service/generic-service.abstract';

@Injectable({ providedIn: 'root' })
export class VariablesAttendanceService extends GenericService<IVariable> {


  path = 'variaveis_site';

  protected collection: AngularFirestoreCollection<IVariable>;
  constructor(angularFirestoreInstace: AngularFirestore) {
    super('variaveis_site', angularFirestoreInstace);
  }


  setPath(accountDocRef: firebase.firestore.DocumentReference) {
    this.setPathCollection(`${accountDocRef.path}`);
  }

    getOnVariables(): Observable<IVariable[]> {
    return new Observable(observer => {
      const subscription =  this.list().subscribe(
        (variables) => {
          const variaveis_site = [];
          variables.forEach(variable => {
            delete variable.docRef;
            variaveis_site.push(variable);
          });

          localStorage.setItem(this.path, JSON.stringify(variaveis_site));       
          subscription.unsubscribe();
          observer.next(variaveis_site);
         
        }
      );
    });
  }

  getLocalVariables(): IVariable[] {
    const variaveis_site: IVariable[] = JSON.parse(localStorage.getItem(this.path));
    return variaveis_site;
  }

  setLocalVariables( variaveis_site: IVariable[]): IVariable[] {
    localStorage.setItem(this.path, JSON.stringify(variaveis_site));
    return variaveis_site;
  }

  setLocalVariavel(key, valor) {
    const variaveis_site: IVariable[] = JSON.parse(localStorage.getItem(this.path));
    const index = (variaveis_site.findIndex(x => x.key.includes(key)));
    variaveis_site[index].value = valor;
    localStorage.setItem(this.path, JSON.stringify(variaveis_site));
    return variaveis_site;
  }

  getVariable(key: string): any {
    const variaveis_site = this.getLocalVariables();
    if (variaveis_site)
    {
      const variable = variaveis_site.find(x => x.key.includes(key))
      if(variable)
        return variable.value;
      else undefined;
    }
      
    else
      return undefined;
  }

  replaceVariableText(texto: string ) {
    let list_variables_attendance: IVariable[] = JSON.parse(localStorage.getItem(this.path));

    if(list_variables_attendance) { 

    } else {
      list_variables_attendance = [];
    }

    list_variables_attendance.forEach(variable => {
      if (variable.value != null)
        texto = texto.replace(variable.key, this.getVariable(variable.key));
    });
    return texto;


   
  }


}
