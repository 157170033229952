export enum EnumComponentes {
  componentZero,
  inputTexto,
  opcoesSelecaoCheckbox,
  informaNome,
  informaEmail,
  informaTelefone,
  informaRamo,
  informaTributacao,
  informaEnquadramento,
  informaFaturamento,
  informaPlanoContaAzul,
  informaPeriodoPagamento,
  informaNumeroSocios,
  informaNumeroFuncionarios,
  calcularMensalidade,
  validarCNPJ,
  servicoDisponivel,
  servicoInDisponivel,
  registraUsuario,
  validarCidade,
  redirecionarAreaDoCliente,
  opcoesRadioButton,
  cidadesAbertura,
  informarNomeEmpresa,
  escolherPlanoPagamento, 
  aberturaEmpresa, 
  descricaoNegocio, 
  inputTextarea,
  botao,
  exibirVideo, 
  taxaAbertura, 
  informarCpf
}

// aberturaEmpresa,
// possuiCnpj,
