 
import { Injectable, EventEmitter } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference } from '@angular/fire/firestore';
import { GenericService } from 'src/shared/generic-service/generic-service.abstract';
import { Account } from '../model-chat/account-chatbot.model';


@Injectable()
export class AccountService extends GenericService<Account> {

  protected collection: AngularFirestoreCollection<Account>;
  constructor(
    angularFirestoreInstace: AngularFirestore,
  ) {
    super('account', angularFirestoreInstace);
    this.collection = angularFirestoreInstace.collection('account');
  }

}
