import { VariablesAttendanceService } from './../shared/services-chat/variables_attendance.service';

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavParams, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  submitted_id = localStorage.getItem('submitted_id');

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar, 
    private variablesService: VariablesAttendanceService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

  }

  ngAfterViewInit() {
 
  }

  ngOnInit() {
    this.variablesService.getOnVariables().subscribe();
  }





}
