// import { MenuOptionGraphComponent } from './components/menu-option-graph/menu-option-graph.component';
import { AccountService } from './../../shared/services-chat/account.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AdminPageRoutingModule } from './admin-routing.module';

import { AdminPage } from './admin.page';
import { MenuAdminComponent } from './components/menu-admin/menu-admin.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AdminPageRoutingModule, 
  ],
  declarations: [AdminPage, MenuAdminComponent
    // , MenuOptionGraphComponent
  ], 
  exports: [MenuAdminComponent], 
  providers: [AccountService],
  entryComponents: [
    // MenuOptionGraphComponent
  ],
})
export class AdminPageModule {}
