import { AuthGuardService } from "./../../shared/services-admin/auth-guard.service";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "page",
    pathMatch: "full",
  },
  {
    path: "template",
    loadChildren: () =>
      import("./template/template.module").then((m) => m.TemplatePageModule),
    data: {
      title: "Templates e-mail",
      url: "/admin/template",
      icon: "mail",
      view: false,
      order: 6,
    },
    canActivate: [AuthGuardService],
  },
  {
    path: "template-create",
    loadChildren: () =>
      import("./template-create/template-create.module").then(
        (m) => m.TemplateCreatePageModule
      ),
    data: {
      title: "template-create",
      url: "/admin/template-create",
      icon: "chevron-forward",
      view: false,
      order: 32,
    },
    canActivate: [AuthGuardService],
  },


  {
    path: "page",
    loadChildren: () =>
      import("./page/page.module").then((m) => m.PagePageModule),
    data: {
      title: "Páginas",
      url: "/admin/page",
      icon: "chevron-forward",
      view: true,
      order: 0,
    },
  },
  {
    path: "page-create",
    loadChildren: () =>
      import("./page-create/page-create.module").then(
        (m) => m.PageCreatePageModule
      ),
    data: {
      title: "Nova página",
      url: "/admin/page-create",
      icon: "chevron-forward",
      view: false,
      order: 1,
    },
  },
  {
    path: "page-update",
    loadChildren: () =>
      import("./page-update/page-update.module").then(
        (m) => m.PageUpdatePageModule
      ),
    data: {
      title: "Update página",
      url: "/admin/page-update",
      icon: "chevron-forward",
      view: false,
      order: 1,
    },
  },
  {
    path: "section",
    loadChildren: () =>
      import("./section/section.module").then((m) => m.SectionPageModule),
    data: {
      title: "Seções",
      url: "/admin/section",
      icon: "chevron-forward",
      view: true,
      order: 1,
    },
  },
  {
    path: "section-create",
    loadChildren: () =>
      import("./section-create/section-create.module").then(
        (m) => m.SectionCreatePageModule
      ),
    data: {
      view: false,
    },
  },
  {
    path: "section-update",
    loadChildren: () =>
      import("./section-update/section-update.module").then(
        (m) => m.SectionUpdatePageModule
      ),
    data: {
      view: false,
    },
  },
  {
    path: "section-text",
    loadChildren: () =>
      import("./section-text/section-text.module").then(
        (m) => m.SectionTextPageModule
      ),
    data: {
      view: false,
    },
  },
  {
    path: "section-list-page",
    loadChildren: () =>
      import("./section-list-page/section-list-page.module").then(
        (m) => m.SectionListPagePageModule
      ),
    data: {
      view: false,
    },
  },
  {
    path: "category",
    loadChildren: () =>
      import("./category/category.module").then((m) => m.CategoryPageModule),
    data: {
      title: "Categorias",
      url: "/admin/category",
      icon: "chevron-forward",
      view: true,
      order: 1,
    },
  },
  {
    path: "category-create",
    loadChildren: () =>
      import("./category-create/category-create.module").then(
        (m) => m.CategoryCreatePageModule
      ),
    data: {
      view: false,
    },
  },
  {
    path: 'social',
    loadChildren: () => import('./social/social.module').then( m => m.SocialPageModule)
    ,data: {
      title: 'Redes sociais',
      url: '/admin/social',
      icon: 'chevron-forward',
      view: true,
      order: 1
    }
  },
  {
    path: 'social-create',
    loadChildren: () => import('./social-create/social-create.module').then( m => m.SocialCreatePageModule)
    ,data: {      
      view: false,   
    },
  },
  // {
  //   path: 'config',
  //   loadChildren: () => import('./config/config.module').then( m => m.ConfigPageModule)
  // },
  {
    path: 'variaveis',
    loadChildren: () => import('./variable/variable.module').then( m => m.VariablePageModule)
  },
  {
    path: 'imagens',
    loadChildren: () => import('../plugins/images-manager/images-manager.module').then( m => m.ImagesManagerPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminPageRoutingModule {}
