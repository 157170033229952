import { UsuarioLogin } from '../model-chat/usuario-login.model';
import { FirestoreService } from './firestore.service';
import { Injectable, EventEmitter } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { GenericService } from 'src/shared/generic-service/generic-service.abstract';

@Injectable()
export class UsuarioLoginService extends GenericService<UsuarioLogin> {

  protected collection: AngularFirestoreCollection<UsuarioLogin>;
  protected collectionFirestore: firebase.firestore.CollectionReference;
  public atualizarAtendimento = new EventEmitter();

  constructor(
    angularFirestoreInstace: AngularFirestore,
    private fb: FirestoreService
  ) {
    super('users', angularFirestoreInstace);
    this.collection = angularFirestoreInstace.collection('users');
    this.collectionFirestore = this.fb.getCollection('users');
  }


}
