import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {

  authState: firebase.User = null;
  
  constructor(
    private auth: AngularFireAuth,
    private router: Router
  ) {

    this.auth.authState.subscribe(auth => {
      this.authState = auth;
      // // console.log( "Verificando Estado atual do usuário => ", this.authState)
    });
  }

  get authenticated(): boolean {
    return this.authState !== null;
  }

  get currentUser(): firebase.User {
    return this.authenticated ? this.authState : null;
  }
  get currentUserObservable(): any {
    return this.auth.authState;
  }

  // Returns current user UID
  get currentUserId(): string {
    return this.authenticated ? this.authState.uid : '';
  }

  // Anonymous User
  get currentUserAnonymous(): boolean {
    return this.authenticated ? this.authState.isAnonymous : false;
  }

  // Returns current user display name or Guest
  get currentUserDisplayName(): string {
    if (!this.authState) {
      return 'Guest';
    } else if (this.currentUserAnonymous) {
      return 'Anonymous';
    } else {
      return this.authState['displayName'] || 'User without a Name';
    }
  }

  //// Social Auth ////

  githubLogin() {
    const provider = new firebase.auth.GithubAuthProvider();
    return this.socialSignIn(provider);
  }

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.socialSignIn(provider);
  }

  facebookLogin() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.socialSignIn(provider);
  }

  twitterLogin() {
    const provider = new firebase.auth.TwitterAuthProvider();
    return this.socialSignIn(provider);
  }

  private socialSignIn(provider) {
    return this.auth.signInWithPopup(provider)
      .then(credential => {
        this.authState = credential.user;
        // this.updateUserData();
      })
      .catch(error => console.log(error));
  }

  //// Anonymous Auth ////

  anonymousLogin() {
    return this.auth
      .signInAnonymously()
      .then(userCredential => {
        this.authState = userCredential.user;
        this.updateUserData();
      })
      .catch(error =>  console.log(error));
  }

  //// Email/Password Auth ////

  emailSignUp(email: string, password: string) {
    return this.auth
      .createUserWithEmailAndPassword(email, password)
      .then(userCredential => {
        this.authState = userCredential.user;
        this.updateUserData();
      })
      .catch(error => console.log(error));
  }

  emailLogin(email: string, password: string) {
    return this.auth
      .signInWithEmailAndPassword(email, password)
      .then(userCredential => {
        this.authState = userCredential.user;
        this.updateUserData();
      })
      .catch(error => console.log(error));
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    const auth = firebase.auth();

    return auth
      .sendPasswordResetEmail(email)
      .then(() => console.log('email sent'))
      .catch(error => console.log(error));
  }

  //// Sign Out ////

  signOut() {
    return this.auth.signOut();
  }

  //// Helpers ////

  private updateUserData(): void {
    // Writes user name and email to realtime db
    // useful if your app displays information about users or for admin features

    const path = `users/${this.currentUserId}`; // Endpoint on firebase
    const data = {
      email: this.authState.email,
      name: this.authState.displayName
    };

    // this.db
    //   .object(path)
    //   .update(data)
    //   .catch(error => // console.log(error));
  }



  checkLogin() {
   const usuario  =  JSON.parse(localStorage.getItem("usuarioLogado"));
   if(usuario) {
     return this.authenticated
   } else 
    return false;
  }


  
}
