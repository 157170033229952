import { DocumentReference } from '@angular/fire/firestore';

import { EnumComponentes } from './../model-chat/enumerations/EnumComponentes';
import { IVariable } from './../model-chat/variable.model';

import { Pergunta } from '../model-chat/pergunta.model';
import { Fluxo } from '../model-chat/fluxo.model';
import { Dialogo } from '../model-chat/dialogo.model';
import { Injectable, EventEmitter } from '@angular/core';
import { variable } from '@angular/compiler/src/output/output_ast';
import * as moment from 'moment';
declare var require: any;
declare function chat(): any;


@Injectable({
  providedIn: 'root'
})
export class UtilService {

  public extenso = require('extenso');
  public removerAcentos = require('remover-acentos');

  // public viewMenuChat =  new EventEmitter();

  constructor() { }

  enumToList(enumObject): string[] {
    return Object.keys(enumObject);
  }

  BRL(valor: number) {
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  }

  scroll() {
    setTimeout(() => {
      const tagHTML = document.getElementById('scroll');
      if (tagHTML) {
        tagHTML.scrollTo(0, tagHTML.scrollHeight);
      }
    }, 100);
  }

  setMomentNull(objeto: Object) {

    Object.keys(objeto).forEach(subObject => {
      if (objeto[subObject] instanceof Object) {
        if (objeto[subObject] instanceof moment) {
          objeto[subObject] = null;
        } else {
          this.setMomentNull(objeto[subObject]);
        }
      }

    });
  }







  public getFluxo(valor, dialogo) {
    const opcao = dialogo.opcoes.find(x => x.valor.toLowerCase() == valor);
    if (opcao) {
      const indice = dialogo.opcoes.indexOf(opcao);
      dialogo.opcoes[indice].selecionado = true;
    }
    return opcao.idFluxo;
  }


  ativarChat() {
    chat();
  }


  getEnunsComponetes() {


    let componentes = [];

    componentes.push(new ComponentDialogo('# Texto curto ', 'Campo para ser utilizado para texto curto. Por exemplo: nome, idade, etc.', EnumComponentes.inputTexto, true));
    componentes.push(new ComponentDialogo('# Texto longo ', 'Campo para ser utilizado para texto longo, Por exemplo: descrição, etc.', EnumComponentes.inputTextarea, true));
    componentes.push(new ComponentDialogo('# Botão', 'Botão para ser usuado como opções simples', EnumComponentes.botao));
    componentes.push(new ComponentDialogo('# Múltipla Escolha', 'Usado quando uma opção deve ser escolhida entre outras', EnumComponentes.opcoesRadioButton));
    componentes.push(new ComponentDialogo('# Lista de seleção', 'Usado quando uma opção deve ser escolhida entre outras como lista', EnumComponentes.opcoesSelecaoCheckbox));
    componentes.push(new ComponentDialogo('# Email', 'Campo para informar o e-mail e verifica se é válido', EnumComponentes.informaEmail));
    componentes.push(new ComponentDialogo('# Redireciona cliente para área da empresa', 'Redireciona cliente para área da empresa', EnumComponentes.redirecionarAreaDoCliente));
    componentes.push(new ComponentDialogo('# Video', 'Exibir video', EnumComponentes.exibirVideo));
    componentes.push(new ComponentDialogo('# Nome cliente', 'Informar nome', EnumComponentes.informaNome));
    componentes.push(new ComponentDialogo('# Serviço disponivel', 'Exibe serviço disponivel', EnumComponentes.servicoDisponivel, false, true));
    componentes.push(new ComponentDialogo('# Serviço indisponivel', 'Exibe serviço indisponivel', EnumComponentes.servicoInDisponivel));
    componentes.push(new ComponentDialogo('# Telefones', 'Informar Telefone celular e residêncial', EnumComponentes.informaTelefone));
    componentes.push(new ComponentDialogo('* Abertura de empresa', 'Plano abertura de empresa', EnumComponentes.aberturaEmpresa));
    //componentes.push(new ComponentDialogo('Exibe opções de mensalidade', 'Exibe opções de mensalidade', EnumComponentes.calcularMensalidade));
    componentes.push(new ComponentDialogo('* Cidades para abertura', 'Lista de cidades para abertura', EnumComponentes.cidadesAbertura));
    componentes.push(new ComponentDialogo('* Descrição do negócio', 'Descrição do negócio da empresa', EnumComponentes.descricaoNegocio, true));
    componentes.push(new ComponentDialogo('* Plano de pagamento', 'Escolher plano de pagamento', EnumComponentes.escolherPlanoPagamento));
    componentes.push(new ComponentDialogo('* Enquadramento', 'Escolher opções de enquadramento', EnumComponentes.informaEnquadramento));
    componentes.push(new ComponentDialogo('* Faturamento', 'Escolher opções de faturamento', EnumComponentes.informaFaturamento));
    componentes.push(new ComponentDialogo('* Funcionários', 'Informar númedo de funcionários', EnumComponentes.informaNumeroFuncionarios));
    componentes.push(new ComponentDialogo('* Sócios', 'Informar númedo de sócios', EnumComponentes.informaNumeroSocios));
    componentes.push(new ComponentDialogo('* Periodo de pagamento', 'Informar periodo de pagamento', EnumComponentes.informaPeriodoPagamento));
    componentes.push(new ComponentDialogo('* Planos conta azul', 'Exibe opções para escolha plano conta azul', EnumComponentes.informaPlanoContaAzul));
    componentes.push(new ComponentDialogo('* Ramos', 'Exibe opções de ramos (Serviço, comércio, ...)', EnumComponentes.informaRamo));
    componentes.push(new ComponentDialogo('* Tributação', 'Exibe opções de tributação (Simples nacional,...)', EnumComponentes.informaTributacao));
    componentes.push(new ComponentDialogo('* Nome empresa', 'Informar nome da empresa', EnumComponentes.informarNomeEmpresa, false, true));
    componentes.push(new ComponentDialogo('* Salva assinatura e registra usuário', 'Salva assinatura empresa e registra usuário', EnumComponentes.registraUsuario));
    componentes.push(new ComponentDialogo('* Solicitar CNPJ', 'Informa e valida CNPJ empresa', EnumComponentes.validarCNPJ));
    componentes.push(new ComponentDialogo('* Valida se cidade', 'Valida se cidade da empresa pode ser atendida', EnumComponentes.validarCidade));
    componentes.push(new ComponentDialogo('* Taxa Abertura empresa', 'Exibe detalhes sobre a taxa de abertura para abertura da empresa', EnumComponentes.taxaAbertura));
    componentes.push(new ComponentDialogo('* Informar CPF', 'Captura o CPF do usuário', EnumComponentes.informarCpf));



    const componentesArrayString = [];

    componentes.forEach(componente => {
      componentesArrayString.push(componente.nome);
    });

    componentesArrayString.sort();

    const componentesObject = [];
    componentesArrayString.forEach(componente => {
      componentesObject.push(componentes.find(x => x.nome.includes(componente)))
    });

    componentes = componentesObject;

    return componentes;

  }


  cleanCnpj(cnpj: string) {
    cnpj = cnpj.replace('.', '');
    cnpj = cnpj.replace('.', '');
    cnpj = cnpj.replace('/', '');
    cnpj = cnpj.replace('-', '');
    return cnpj;
  }


  separarValor(stringValor: any, indice) {
    const arr = stringValor.split(',');
    return arr[indice];
  }



  removeSplashBrowser() {
    const splash = document.getElementById('splash-screen')
    splash.style.opacity = '0'
    setTimeout(() => { splash.remove() }, 300)
  }

  openUrl(url) {
    window.open(
      url,
      '_blank' 
    );
  }



}



export interface IComponentDialogo {
  nome?: string;
  descricao?: string;
  valor?: EnumComponentes,
  exibirLabel?: boolean,
  disable?: boolean
}

export class ComponentDialogo implements IComponentDialogo {
  constructor(
    public nome?: string,
    public descricao?: string,
    public valor?: EnumComponentes,
    public exibirLabel?: boolean,
    public disable?: boolean
  ) {
    this.nome = this.nome || null;
    this.descricao = this.descricao || null;
    this.valor = this.valor || null;
    this.exibirLabel = this.exibirLabel || false;
    this.disable = this.disable || null;
  }
}
