import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from './../../shared/services-chat/account.service';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.page.html',
  styleUrls: ['./admin.page.scss'],
})
export class AdminPage implements OnInit {

  account_id: string = localStorage.getItem("account_id");

  constructor(
    private accountService: AccountService,   
    private activatedRoute: ActivatedRoute,  
    private router: Router

  ) { }

 

  ngOnInit() {



    // this.activatedRoute.params.subscribe(params => {
    //   if (params['account_id']) {       
    //     this.account_id = params['account_id'];
    //   }
    // });

    // if(this.account_id == undefined) 
    // this.account_id =  environment.account;

    // this.accountService.get(this.account_id).subscribe(
    //   response => {      
    //     localStorage.setItem("account_id", response.id);
    //     localStorage.setItem("account_path", response.docRef.path);
    //     localStorage.setItem("user", response.docRef.path + '/user');
    //     localStorage.setItem("user", response.docRef.path + '/user');
    //     localStorage.setItem("context", response.docRef.path + '/context');
    //     localStorage.setItem("publish", response.docRef.path + '/publish');
    //     localStorage.setItem("variable", response.docRef.path + '/variable');
    //     localStorage.setItem("counter", response.docRef.path + '/counter');

    //     this.router.navigateByUrl("/admin/account");
    //   }
    // )
  }


}
