
export class Pergunta {

    texto: string;
    acento: string;
    usarNome =  false;
    dataEnvio: Date =  new Date();

    constructor(objeto = null) {
        if (objeto !== null) {
            this.texto = (objeto.texto) ? objeto.texto : '' ;
            this.acento = (objeto.acento) ? objeto.acento : '' ;
            this.usarNome =  (objeto.usarNome) ? objeto.usarNome : false;
        }
    }
}

