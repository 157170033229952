import { ScriptService } from 'src/shared/services-chat/script.service';
import { AlertController } from '@ionic/angular';
import { IScript } from './../model-chat/script.model';
import { Fluxo } from '../model-chat/fluxo.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { GenericService } from 'src/shared/generic-service/generic-service.abstract';
import { Pergunta } from '../model-chat/pergunta.model';


@Injectable()
export class FluxoService extends GenericService<Fluxo> {

  // fluxos: Fluxo[] = [];
  // fluxosOrdenados: Fluxo[] = [];

  constructor(
      angularFirestoreInstace: AngularFirestore,
      public alertController: AlertController,
      public scriptService: ScriptService
      ) { super('', angularFirestoreInstace) }

  setPath(parent: firebase.firestore.DocumentReference) {
    // // console.log(parent.path + '/flow')
    this.setPathCollection(parent.path + '/flow');
  }



  addFlow(flow: Fluxo):  Observable<Fluxo> {
    return new Observable(observer => {
      this.add(flow).then((flowResponse) => 
      {
        const retorno   = this.scriptService.get(flowResponse.script.id).subscribe(
          script => {
            script.flows.push(flowResponse.docRef);
            script.docRef.update(script);   
            retorno.unsubscribe();        
          }
        )
        observer.next(flowResponse);
      });
    });
  }


  async deleteFlow(fluxo: Fluxo) {

    // const alert = await this.alertController.create({
    //   message: 'Fluxo deletado com sucesso!',
    //   buttons: [
    //     {
    //       text: 'Ok',
    //       role: 'cancel'
    //     }
    //   ]
    // });

    this.delete(fluxo.id).then(
      () => {
        const retorno  = this.scriptService.get(fluxo.script.id).subscribe(
          script => {
            retorno.unsubscribe();
            const index  = script.flows.findIndex(x => x.id.includes(fluxo.id));
            script.flows.splice(1,index);
            script.docRef.update(script)
            // alert.present();
          }
        )
      }
    );

  }


  getFluxos(scriptDocRef: firebase.firestore.DocumentReference): Observable<Fluxo[]> {
    this.setPath(scriptDocRef);

    return this.list();

    // return new Observable(observer => {
    //   const retorno  = this.list().subscribe(
    //     responseFluxos => {
    //       const fluxos = [];
    //       responseFluxos.forEach(fluxo => {
    //         fluxos.push(this.converterPerguntas(fluxo));
    //       });
    //       retorno.unsubscribe();
    //       observer.next(fluxos);
    //     }
    //   );
    // });
  }



  public ordenarFluxos(fluxos: Fluxo[], fluxosOrdenados: Fluxo[]): Fluxo[] {      
    fluxosOrdenados = fluxosOrdenados;
    const fluxoInicial = fluxos.find(x => x.inicial === true);
    if(fluxoInicial)
    this.addFluxoOrdem(fluxoInicial.id, fluxos, fluxosOrdenados);
      fluxos.forEach(fluxo => {
      this.addFluxoOrdem(fluxo.id, fluxos, fluxosOrdenados);
    });
    return fluxosOrdenados;
  }


  // private converterPerguntas(fluxo: Fluxo): Fluxo {
  //   fluxo.dialogos.forEach(dialog => {
  //     for (let i = 0; i < dialog.perguntas.length; i++) {
  //       dialog.perguntas[i] = new Pergunta(dialog.perguntas[i]);
  //     }
  //     for (let i = 0; i < dialog.complementoPerguntas.length; i++) {
  //       dialog.complementoPerguntas[i] = new Pergunta(dialog.complementoPerguntas[i]);
  //     }
  //   });
  //   return fluxo;
  // }

  private addFluxoOrdem(idFluxo, fluxos: Fluxo[], fluxosOrdenados: Fluxo[]) {
    const query = fluxosOrdenados.find(x => x.id === idFluxo);
    if (!query) {
      const fluxo = fluxos.find(x => x.id === idFluxo);
      if(fluxo) {
        fluxosOrdenados.push(fluxo);      
        fluxo.dialogos.forEach(dialogo => {
          if (dialogo.idFluxoResposta) {           
            this.addFluxoOrdem(dialogo.idFluxoResposta, fluxos, fluxosOrdenados);
          }
          dialogo.opcoes.forEach(opcao => {
            if (opcao.idFluxo) {
              this.addFluxoOrdem(opcao.idFluxo, fluxos, fluxosOrdenados);
            }
          });
        });
      } else { return; }
    
    } else { return; }
  }




  async deleteConfirm(fluxo: Fluxo): Promise<any> {

    const alert = await this.alertController.create({
      header: `Deletar fluxo!`,
      message: `Você está certo que deseja deletar o fluxo ${fluxo.nome}`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'primary',
          handler: (blah) => {
            // return new Promise<any>((resolve, reject) => { resolve();});            
          }
        }, {
          text: 'Deletar',
          handler: () => {
            this.deleteFlow(fluxo);
          }
        }
      ]
    });

    await alert.present();

  }






  




}
