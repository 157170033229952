import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

import * as firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class AppService {
  private fb: firebase.app.App;
  private firestore: firebase.firestore.Firestore;
  private auth: firebase.auth.Auth;

  constructor() {
    this.initializeApp();
  }

  private initializeApp() {
    if (!firebase.apps.length) {
      this.fb = firebase.initializeApp(environment.firebase);
    } else {
      this.fb = firebase.app();
    }
  }

  initializeFirestore(): firebase.firestore.Firestore {
    this.firestore =  firebase.firestore();
    return this.firestore;
  }

  initializeAuth(): firebase.auth.Auth {
    this.auth =  firebase.auth();
    return this.auth;
  }
}
