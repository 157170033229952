import { AngularFirestore, AngularFirestoreCollection, CollectionReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IFilterParam, IOrderByFilterParam } from './filter-param.aux.model';
import { GenericEntity } from './generic-entity.model';
import { IGenericService } from './generic-service.interface';



export abstract class GenericService<T extends GenericEntity> implements IGenericService<T> {

  protected collection: AngularFirestoreCollection<T>;
  // pathCollection: string; 

  constructor(path: string, protected afs: AngularFirestore) {
    // console.log(path)
    if(path != '')
      this.collection = this.afs.collection(path);
  }

  protected setPathCollection(path: string) {
    this.collection = this.afs.collection(path);
    // // console.log(this.collection.ref.path)
  }

  private prepareQuery(limit?: number, filtersOrderBy?: IOrderByFilterParam[], ...filters: IFilterParam[]): CollectionReference | firebase.firestore.Query {
    let colRef = this.collection.ref;
    let filtro: CollectionReference | firebase.firestore.Query;

    // if has filters params
    if (filters && filters.length === 0) {
      filtro = colRef;
    }

    if (filters && filters.length > 0) {
      filters.forEach((element, index) => {
        let operatorElement;

        if (element.operator) {
          operatorElement = element.operator;
        } else {
          operatorElement = '==';
        }

        if (index == 0) {
          filtro = colRef.where(element.field, operatorElement, element.value);
        } else {
          filtro = filtro.where(element.field, operatorElement, element.value);
        }
      });
    }

    // if has orderby params
    if (filtersOrderBy && filtersOrderBy.length > 0) {
      filtersOrderBy.forEach(element => {
        if (element.direction) {
          filtro = filtro.orderBy(element.fieldPath, element.direction);
        } else {
          filtro = filtro.orderBy(element.fieldPath);
        }
      });
    }

    // if has limit
    limit = limit ? limit : 1000;
    filtro = filtro.limit(limit);
    return filtro;
  }

  add(item: T): Promise<T> {
    const promise = new Promise<T>((resolve, reject) => {

      this.collection.add(Object.assign({}, item)).then(ref => {

      this.get(ref.id).subscribe(
        newItem => {
          resolve(newItem);
        }
      );

        ref.update({ id: ref.id, docRef: ref }).then(
          () => {
            item.id = ref.id;
            item.docRef = ref;

            const newItem = {
              ...(item as T)
            };
            resolve(newItem);
          }
        );

      });
    });
    return promise;
  }

  set(item: T, name: string): Promise<T> {
    const promise = new Promise<T>((resolve, reject) => {
      this.collection.doc(name).set(Object.assign({}, item)).then(
        res => {
          this.collection.doc(name).get().subscribe(snapshot => {
            snapshot.ref.update({ id: snapshot.id, docRef: snapshot.ref }).then(
              () => {
                item.id = snapshot.ref.id;
                item.docRef = snapshot.ref;
                const newItem = {
                  ...(item as T)
                };
                resolve(newItem);
              }
            );
          });
        }
      );
    });
    return promise;
  }

  update(item: T): Promise<T> {
    
    const promise = new Promise<T>((resolve, reject) => {
      const docRef = this.collection
        .doc<T>(item.docRef.id)
        .set(Object.assign({}, item))
        .then(() => {
          resolve(
            {
            ...(item as T)
          });
        });
    });
    return promise;
  }

  list(): Observable<T[]> {

    // return this.collection.valueChanges();

    return this.collection
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a.payload.doc.data() as T;           
            data.docRef = a.payload.doc.ref;
            data.id = a.payload.doc.ref.id
            return data;
          });
        })
      );
  }

  get(id: string): Observable<T> {
    return this.collection
      .doc<T>(id)
      .snapshotChanges()
      .pipe(
        map(doc => {
          if (doc.payload.exists) {         
            const data = doc.payload.data() as T;
             data.id = doc.payload.id;
             data.docRef = doc.payload.ref;
            return data;
          }
        })
      );
  }

  delete(id: string): Promise<T> {
    const promise = new Promise<T>((resolve, reject) => {
      const subscription =   this.get(id).subscribe(
        doc => {
          subscription.unsubscribe()
          doc.docRef.delete().then(
            () => {
              resolve(doc);
            }
          );
        })
    });
    return promise;
  }

  generateDocId(): firebase.firestore.DocumentReference {
    return this.collection.ref.doc();
  }

  getByField(campo: string, valor: any, limit?: number): Observable<T[]> {
    let itens: T[] = [];
    limit = limit ? limit : 10;
    const filtro = this.collection.ref.where(campo, '==', valor).limit(limit);
    return new Observable(observer => {
      filtro.onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
        snapshot.docChanges().forEach(change => {
          if (change.type == 'added') {
            const obj = <T>change.doc.data();
            if (obj.id == null) {
              obj.id = change.doc.id;
              obj.docRef = change.doc.ref;
            }
            itens.push(<T>obj);
          }
          if (change.type == 'modified') {
            const index = itens.findIndex(e => e.docRef.id == change.doc.ref.id);
            itens[index] = <T>change.doc.data();
          }
          if (change.type == 'removed') {
            // console.log('itens');
            // console.log(itens);
            itens = itens.filter(e => e.docRef.id != change.doc.ref.id);
          }
        });
        observer.next(itens);
      });
    });
  }

  getByFieldArrayContainsAnyAndOrderBy(campo: string, valor: string[], orderByField: string): Observable<T[]> {
    let itens: T[] = [];
    // @ts-ignore
    const filtro = this.collection.ref.where(campo, "array-contains-any", valor).orderBy(orderByField).limit(50);
    return new Observable(observer => {
      filtro.onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
        snapshot.docChanges().forEach(change => {
          if (change.type == 'added') {
            const obj = <T>change.doc.data();

            if (obj.id == null) {
              obj.id = change.doc.id;
              obj.docRef = change.doc.ref;
            }
            itens.push(<T>obj);
          }
          if (change.type == 'modified') {
            const index = itens.findIndex(e => e.docRef.id == change.doc.ref.id)
            itens[index] = <T>change.doc.data();
          }
          if (change.type == 'removed') {
            itens = itens.filter(e => e.docRef.id != change.doc.ref.id)
          }
        });
        observer.next(itens);
      });
    });
  }

  getByFields(limit?: number, filtersOrderBy?: IOrderByFilterParam[], ...filters: IFilterParam[]): Observable<T[]> {
    let itens: T[] = [];
    const filtro: CollectionReference | firebase.firestore.Query = this.prepareQuery(limit, filtersOrderBy, ...filters);

    // let colRef = this.collection.ref;
    // let filtro: CollectionReference | firebase.firestore.Query;


    // // if has filters params
    // if (filters && filters.length === 0) {
    //   filtro = colRef;
    // }

    // if (filters && filters.length > 0) {
    //   filters.forEach((element, index) => {
    //     let operatorElement;

    //     if (element.operator) {
    //       operatorElement = element.operator;
    //     } else {
    //       operatorElement = '==';
    //     }

    //     if (index == 0) {
    //       filtro = colRef.where(element.field, operatorElement, element.value);
    //     } else {
    //       filtro = filtro.where(element.field, operatorElement, element.value);
    //     }
    //   });
    // }

    // // if has orderby params
    // if (filtersOrderBy && filtersOrderBy.length > 0) {
    //   filtersOrderBy.forEach(element => {
    //     if (element.direction) {
    //       filtro = filtro.orderBy(element.fieldPath, element.direction);
    //     } else {
    //       filtro = filtro.orderBy(element.fieldPath);
    //     }
    //   });
    // }

    // // if has limit
    // limit = limit ? limit : 1000;
    // filtro = filtro.limit(limit);

    return new Observable(observer => {
      filtro.onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
        snapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            const obj = <T>change.doc.data();

            if (obj.id == null) {
              obj.id = change.doc.id;
              obj.docRef = change.doc.ref;
            }
            itens.push(<T>obj);
          }
          if (change.type === 'modified') {
            const index = itens.findIndex(e => e.docRef && e.docRef.id === change.doc.ref.id);
            itens[index] = <T>change.doc.data();
          }
          if (change.type === 'removed') {
            // console.log('itens');
            // console.log(itens);
            itens = itens.filter(e => e.docRef.id !== change.doc.ref.id);
          }
        });
        observer.next(itens);
      });
    });
  }

  getTotal(limit?: number, filtersOrderBy?: IOrderByFilterParam[], ...filters: IFilterParam[]): Observable<number> {
    const filtro: CollectionReference | firebase.firestore.Query = this.prepareQuery(limit, filtersOrderBy, ...filters);

    return new Observable(observer => {
      filtro.onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
        observer.next(snapshot.size);
      });
    });

  }



}
