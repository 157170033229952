import { NavController } from '@ionic/angular';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from './../../../../shared/services-admin/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-admin',
  templateUrl: './menu-admin.component.html',
  styleUrls: ['./menu-admin.component.scss'],
})
export class MenuAdminComponent implements OnInit {

  public selectedIndex = 0;

  public appPages = [];

  constructor(  
    private navCtrl: NavController,
    private authService: AuthService, 
    private _router: Router, 
    private activatedRoute: ActivatedRoute
      
    ) { }

  ngOnInit() { 


   console.log(window.location.pathname);

   


    this._router.config.forEach(router => {
      if(router.data) {
        if(router.data.view) 
        this.appPages.push(router.data)
      } else if(router.path != "" &&  router.path != "admin" &&  router.path != "auth/login" ){
        const data  = {
          title: router.path,
          url: `/admin/${router.path}`,
          icon: 'chevron-forward',
          view: true,
          order: 0
        }
        this.appPages.push(data)
      }
    });

    this.appPages.sort((a, b) =>  a.order - b.order);

    console.log( this.appPages);


    this.selectedIndex = this.appPages.findIndex( x => x.url == window.location.pathname);



    // this._router.events.subscribe(
    //   response => {
    //     if(response instanceof NavigationEnd) {
    //       const segments = response.url.split('/');
    //       let rota: any = {data: {title: 'DOBEM ;)'}};
    //       segments.forEach(segment => {
    //          const result  =  this.appPages.find(x => x.url.includes(segment));
    //          if(result) 
    //          rota = result;
    //       });
    //       document.title = rota.title;
    //     }
    //   }
    // );




  }

  logout() {
    this.authService.signOut().then(() => {
      // this._router.navigateByUrl('/auth/login');
      // this.navCtrl.navigateRoot(`/auth/login`)
      window.location.href = '/auth/login';
    }
    );

  }

}
