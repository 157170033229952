import { Contador } from '../model-chat/contador.model';
import { Fluxo } from '../model-chat/fluxo.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { GenericService } from 'src/shared/generic-service/generic-service.abstract';
import { Pergunta } from '../model-chat/pergunta.model';


@Injectable()
export class ContadoresService extends GenericService<Contador> {
    
 
    protected collection: AngularFirestoreCollection<Contador>;
    constructor(angularFirestoreInstace: AngularFirestore) {
      super((localStorage.getItem("account_path"))? `${localStorage.getItem("account_path")}/counter`  : '', angularFirestoreInstace);
    }

    setPath(accountDocRef:  firebase.firestore.DocumentReference) {
      this.setPathCollection(accountDocRef.path + '/counter');
    }

}
