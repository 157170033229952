import { Observable } from 'rxjs';

import { Context } from './../model-chat/context.model';
import { IScript, Script } from './../model-chat/script.model';

import { Injectable, EventEmitter } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference } from '@angular/fire/firestore';
import { GenericService } from 'src/shared/generic-service/generic-service.abstract';


@Injectable()
export class  ScriptService extends GenericService<IScript> {

  protected collection: AngularFirestoreCollection<IScript>;
  constructor(angularFirestoreInstace: AngularFirestore) {
    super('', angularFirestoreInstace);
    // this.collection = angularFirestoreInstace.collection(script);
  }

  setPath(parent:  firebase.firestore.DocumentReference) {
    this.setPathCollection(parent.path + '/script');
  }

  create(script: IScript) {
    this.setPath(script.context);
    return this.add(script);
  }


}
