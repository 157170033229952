export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAvPm53gb_gS_5hOcPnq4GdzsQN4v_xVvk",
    authDomain: "site-directionit.firebaseapp.com",
    projectId: "site-directionit",
    storageBucket: "site-directionit.appspot.com",
    messagingSenderId: "940146896868",
    appId: "1:940146896868:web:a8caf8488e1d38f61b21f1",
    measurementId: "G-Z1PFBHB3JR"
  },
  account: "0vGLbBIJQG4edJ70o2Xx",


  urlApiSistemaInterno: 'http://localhost:8080/api/',
  urlApiCNPJ: 'http://localhost:8080/api/consulta-cnpj/',
  SERVER_API_URL: 'http://localhost:8080/',
  SERVER_API_FUNCTIONS: 'http://localhost:5001/chatbot-desenv/us-central1',
  URL_AREA_EMPRESA: 'http://localhost:8101'
};



